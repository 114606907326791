input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom {z-index: 0 !important}

.additional-condition-bar .p-scrollpanel-bar {
  background-color: #a8a8a8 !important;
  opacity: 1 !important;
}


.autocomplete {
  padding: 8px;
  border: 1px solid #D1D5DBFF;
  border-radius: 5px;
}

.autocomplete:focus {
  outline: 2px solid #6366F1;
}
.text-area-container {
  display: flex;
  flex-direction: column;
}
.align-self-center{
  justify-self: center;
}